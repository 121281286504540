<template>
  <div class="modal-column">
    <div class="modal-column-body mb-32px">
      <div class="my-32px text-18px">
        请在服务商处购买订购码，如已购买，请输入订购码：
      </div>
      <div class="h-input-group">
        <span class="h-input-addon">订购码</span>
        <Input v-model="code" placeholder="请输入订购码，订购账套数为n开头，账套续期为t开头"/>
      </div>
    </div>
    <div class="modal-column-between">
      <vxe-button :loading="loading" @click="$emit('close')">取消</vxe-button>
      <vxe-button :loading="loading" :disabled="!code" status="primary" @click="doConfirm">确认订购</vxe-button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022陕西丁甲数据科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年12月05日</li>
 * <li>@author     : </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import common from "@/api/common";
import {message} from "heyui.ext";

export default {
  name: "Renewal",
  props: {
    astId: String
  },
  data() {
    return {
      code: null,
      loading: false,
    }
  },
  methods: {
    doConfirm() {
      if (this.code) {
        this.loading = true;
        common.renewalValid(this.astId, this.code).then(({success, data}) => {
          if (success) {
            if (data.type === 'astNum') {
              message(`已成功增加可创建账套数：${data.month}套`);
            } else {
              message(`已为账套续期${data.month}个月`);
            }
            this.$store.dispatch('init');
            this.$emit('success');
          }
        }).finally(() => this.loading = false)
      }
    }
  },
}
</script>

<style scoped>

</style>
