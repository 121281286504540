<template>
  <div data-v-5f295f02="" class="container">
    <!--  <div class="login">-->
    <!--    <div class="login-left">-->
    <!--      <div class="login-left-box">-->
    <!--&lt;!&ndash;        <img class="login-left-fxy" v-if="webConfig.loginLogo" :src="'/api' + webConfig.loginLogo">&ndash;&gt;-->
    <!--&lt;!&ndash;        <img class="login-left-fxy" v-else src="../assets/login/login-fxy.png">&ndash;&gt;-->
    <!--        <h1 class="login-left-title">{{ webConfig.loginSlogan || '企业私人订制财务系统' }}</h1>-->
    <!--        <div class="text-18px mt-16px tracking-2px">{{ webConfig.loginSubSlogan || '财务管理更安全、更私密、更简单！' }}</div>-->
    <!--        <img class="login-left-img" src="../assets/login/login-img.png">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="login-right">-->
    <!--      <div class="login-right-top">-->
    <!--&lt;!&ndash;        <template v-if="GIT==1">&ndash;&gt;-->
    <!--&lt;!&ndash;          <a href="https://gitee.com/flyemu/public-financial" target="_blank">&ndash;&gt;-->
    <!--&lt;!&ndash;            <i class="fa fa-github"></i>&ndash;&gt;-->
    <!--&lt;!&ndash;            Gitee&ndash;&gt;-->
    <!--&lt;!&ndash;          </a>&ndash;&gt;-->
    <!--&lt;!&ndash;          <DropdownCustom class="mx-16px" placement="left-start" trigger="hover" :toggleIcon="false">&ndash;&gt;-->
    <!--&lt;!&ndash;            <span> <i class="fa fa-wechat"></i> 源码咨询</span>&ndash;&gt;-->
    <!--&lt;!&ndash;            <template #content>&ndash;&gt;-->
    <!--&lt;!&ndash;              <div class="w-250px h-250px rounded-md overflow-hidden">&ndash;&gt;-->
    <!--&lt;!&ndash;                <img class="w-full" v-if="webConfig.qrCode" :src="'/api'+webConfig.qrCode">&ndash;&gt;-->
    <!--&lt;!&ndash;                <img class="w-full" v-else src="../assets/code.png">&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            </template>&ndash;&gt;-->
    <!--&lt;!&ndash;          </DropdownCustom>&ndash;&gt;-->
    <!--&lt;!&ndash;        </template>&ndash;&gt;-->
    <!--      </div>-->
    <!--      <div class="login-right-center">-->
    <!--        <transition name="scale" mode="out-in">-->
    <!--          <component :is="viewName" @changeView="changeView"/>-->
    <!--        </transition>-->
    <!--      </div>-->
    <!--      <div class="login-right-copyright">-->
    <!--        {{ webConfig.copyright || 'Powered by ' + APP_COPM }} {{ year }}-->
    <!--        <a v-if="webConfig.icp" href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">{{ webConfig.icp }}</a>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <div id="userLayout" class="user-layout-wrapper desktop">
      <div class="container">
        <div class="top">
          <div class="header">
            <!--          <img src="@/assets/logo/logo.png" class="logo" alt="logo">-->
          </div>
        </div>
        <div class="main">
          <div class="login-left"></div>
          <div class="login-right">
            <div class="login-main">
              <div class="login-top">{{ viewName=='LoginForm'?'欢迎登录':'欢迎注册' }}</div>
              <div class="login-title">
                <hr/>
                <span data-v-441cd03a="">企数通云财税</span>
              </div>
              <transition name="scale" mode="out-in">
                <component :is="viewName" @changeView="changeView"/>
              </transition>
              <!--            <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">-->
              <!--              &lt;!&ndash;              <h3 class="title">VOnline后台管理系统</h3>&ndash;&gt;-->
              <!--              <el-form-item prop="username">-->
              <!--                <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">-->
              <!--                  <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon"/>-->
              <!--                </el-input>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item prop="password">-->
              <!--                <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"-->
              <!--                          @keyup.enter.native="handleLogin">-->
              <!--                  <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon"/>-->
              <!--                </el-input>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item prop="code" v-if="captchaEnabled">-->
              <!--                <el-input v-model="loginForm.code" auto-complete="off" placeholder="验证码" style="width: 63%"-->
              <!--                          @keyup.enter.native="handleLogin">-->
              <!--                  <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon"/>-->
              <!--                </el-input>-->
              <!--                <div class="login-code">-->
              <!--                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>-->
              <!--                </div>-->
              <!--              </el-form-item>-->
              <!--              <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>-->
              <!--              <el-form-item style="width:100%;">-->
              <!--                <el-button :loading="loading" size="medium" type="primary" class="login-button"-->
              <!--                           @click.native.prevent="handleLogin">-->
              <!--                  <span v-if="!loading">登 录</span>-->
              <!--                  <span v-else>登 录 中...</span>-->
              <!--                </el-button>-->
              <!--                <div style="float: right;" v-if="register">-->
              <!--                  <router-link class="link-type" :to="'/register'">立即注册</router-link>-->
              <!--                </div>-->
              <!--              </el-form-item>-->
              <!--            </el-form>-->
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="footer_title">
            <img src="@/assets/login/bottom_label.png">
            <div class="links">企数通云财税</div>
          </div>
          <!--        <div class="copyright">Copyright © 2018-2022 zyz.vip All Rights Reserved.</div>-->
          <div class="copyright">
            Copyright © {{ year }} {{ webConfig.copyright || 'Powered by ' + APP_COPM }} |
            <a v-if="webConfig.icp" href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">{{ webConfig.icp }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022陕西丁甲数据科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年09月22日</li>
 * <li>@author     : </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import LoginForm from "@/views/login/LoginForm.vue";
import ForgotPassword from "@/views/login/ForgotPassword.vue";
import Registered from "@/views/login/Registered.vue";
import {mapState} from "vuex";

export default {
  name: "Login",
  components: {LoginForm, ForgotPassword, Registered},
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    ...mapState(['webConfig'])
  },
}
</script>
<script setup>

import {ref} from "vue";

const viewName = ref('LoginForm');

const changeView = (toView) => {
  viewName.value = toView;
}

localStorage.removeItem("currentTab");
</script>
<style lang="less">
.container {
  max-width: 100% !important;
  overflow: hidden;
}

.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 38px;
}
</style>
<style lang="less" scoped>
.main {
  // top: 50px;
  top: 15%;
  box-shadow: 1px 10px 20px 1px rgba(105, 105, 105, 0.23);
}

button.login-button {
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  width: 100%;
  border: #15B0ED;
  background: linear-gradient(to right, #008fd5 0%, #15B0ED 100%);
}

button.login-button:hover {
  background: linear-gradient(to left, #15B0ED 0%, #15B0ED 100%);
}

.login-left {
  height: 600px;
  width: 620px;
  background: url(~@/assets/login/img04.png) no-repeat;
  position: absolute;
  left: 0;
  border-radius: 20px 0 0 20px;
  background-position: center;
  background-size: 100% 100%;
}

.login-title {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
}

.login-title hr {
  position: absolute;
  top: 50%;
  width: 80%;
  margin: auto;
  left: 0;
  right: 0;
  //display: block;
  //unicode-bidi: isolate;
  //margin-block-start: 0.5em;
  //margin-block-end: 0.5em;
  //margin-inline-start: auto;
  //margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}

.login-title span {
  background: #FFF;
  padding: 0 8%;
  position: relative;
  z-index: 1;
}

.login-top {
  font-size: 36px;
  text-align: center;
  letter-spacing: 5px;
  margin-bottom: 20px;
}

.login-right {
  width: 620px;
  position: absolute;
  right: 0px;
  height: 600px;
}

.login-main {
  position: absolute;
  width: 400px;
  height: 410px;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.user-layout-tabs {
  textAlign: 'center';
  borderBottom: 'unset';
}

.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
    color: #15B0ED;
    font-size: 16px;
  }

  .forge-password {
    font-size: 14px;
    color: #15B0ED;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}

@media screen and (max-width: 1260px) {
  .login-left {
    display: none;
  }

  .login-right {
    width: 100%;
  }

  .login-right {
    right: 50%;
    /*margin-right: -250px;*/
    transform: translate(50%, 0);
  }
  .main {
    width: 700px !important;
  }
}

@media screen and (max-width: 700px) {
  .login-main {
    width: 350px !important;
  }

}

/*@media screen and (max-width: 520px) {*/
/*  .login-main {*/
/*    width: 340px !important;*/
/*  }*/
/*}*/

</style>
<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        // background: #f0f2f5 url(~@/assets/login/bg02.png) no-repeat 50%;
        // max-width: 1000px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    height: 100vh;
    /* min-height: 100%; */
    //background-image: url("../assets/login/login-bg.png");
    background: #f0f2f5 url("../assets/login/bg03.png") no-repeat;
    background-size: 100% 100%;
    /* padding-top: 100px; */
    position: relative;

    a {
      text-decoration: none;
    }

    .top {
      text-align: center;
      position: absolute;
      left: 40px;
      top: 20px;

      .header {
        height: 44px;
        line-height: 44px;

        .badge {
          position: absolute;
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          margin-left: -12px;
          margin-top: -10px;
          opacity: 0.8;
        }

        .logo {
          /*height: 44px;*/
          height: 60px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }
      }
    }

    .main {
      /*background-image: url(~@/assets/login/bg02.png);*/
      background: #ffffff;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      // background: #FFFFFF url(~@/assets/login/bg02.png) no-repeat 100%;
      border-radius: 20px;
      min-width: 260px;
      width: 1240px;
      margin: 0 auto;
      position: relative;
      height: 600px;
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 16px 0 16px;
      text-align: center;

      .footer_title {
        position: relative;
      }

      .footer_title img {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
      }

      .links {
        margin-bottom: 14px;
        font-size: 14px;
      }

      .copyright {
        color: rgba(0, 0, 0);
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .footer_title {
    img {
      display: none !important;
    }
  }

  #userLayout.user-layout-wrapper {
    .container {
      padding-top: 0;
    }

    /*.main{*/
    /*  top: 50%;*/
    /*  margin-top: -335px;*/
    /*}*/
  }
}

</style>
